import React from "react";

import {
  Text,
  Image,
  Container,
  Button,
  ButtonAlt,
  HighlightedText,
} from "@atoms";
import { ActionShort, HeadingWIcon, Share } from "@molecules";
import brand from "@utils/brand";
import classNames from "classnames";

const Cta = React.memo(
  ({
    icon,
    heading,
    copy,
    backgroundImage,
    action,
    secondaryLink,
    path,
    url,
    showAction: _showAction,
  }) => {
    const showAction = action?.actionType === "signUp" && _showAction;
    const { lakota, lgca, romero } = brand;
    return (
      <div
        className={classNames("relative py-12 sm:py-24", {
          "bg-blue": lgca,
          "bg-black": lakota,
          "bg-blue-light": romero,
          "text-white": !romero,
        })}
      >
        <div
          className={classNames("absolute right-0 top-0 bottom-0 z-0", {
            "w-0 md:w-1/2": romero || (lgca && !showAction),
            "opacity-80": !romero,
            "w-full": lakota,
          })}
        >
          {backgroundImage && (
            <Image
              image={backgroundImage}
              ixParams={!romero ? { exp: "-7", con: 5 } : { gam: 5, con: 5 }}
              fill
              fit="cover"
            />
          )}
        </div>
        <div className="relative">
          {/* <AnimateIn> */}
          <Container>
            <div className="-mx-3 flex flex-shrink-0 flex-wrap md:flex-nowrap">
              <div
                className={classNames("my-6 px-3", {
                  "pr-3 sm:pr-6 md:w-3/5 lg:w-2/3": showAction,
                  "pr-3 sm:pr-6 md:w-1/2 lg:pr-12": !showAction && !lakota,
                })}
              >
                {!romero && (
                  <HeadingWIcon
                    heading={
                      action && action?.heading?.length
                        ? action.heading
                        : heading || heading
                    }
                    icon={icon}
                    inverse={!romero}
                    small
                  />
                )}
                {romero && (
                  <HighlightedText
                    text={
                      action && action?.heading?.length
                        ? action.heading
                        : heading || heading
                    }
                    highlightText={"Justice Warrior" || null}
                    variant="h3"
                    className="font-medium text-blue-dark"
                    animate
                  />
                )}
                <Text
                  variant={romero ? "body" : "body--large"}
                  className="mt-4"
                >
                  {action && action?.copy?.length ? action.copy : copy || copy}
                </Text>
                {romero && showAction && action && (
                  <div to={action.url} className="mt-6 w-full text-white">
                    <ActionShort {...action} url={action.url} />
                  </div>
                )}
                {romero && showAction && !action && (
                  <div className="mt-6 w-full">
                    <Share url={url} />
                  </div>
                )}
                {romero && !showAction && action !== null && (
                  <div className="mt-6 w-full">
                    <Button
                      rounded
                      outline
                      icon
                      color="gold"
                      size="sm"
                      to={action.url}
                    >
                      {action.cta || "Take Action"}
                    </Button>
                  </div>
                )}
                {romero &&
                  secondaryLink &&
                  secondaryLink.link &&
                  secondaryLink.text && (
                    <div className="mt-6">
                      <ButtonAlt to={secondaryLink.link} color="gold">
                        {secondaryLink.text}
                      </ButtonAlt>
                    </div>
                  )}
              </div>
              {!romero && (
                <div
                  className={classNames(
                    "my-6 ml-auto flex flex-col px-3 sm:items-center sm:justify-center",
                    {
                      "w-full md:w-2/5  lg:w-1/3": showAction,
                      "w-full md:w-1/2": lgca && !showAction,
                    }
                  )}
                >
                  {showAction && action && (
                    <div to={action.url} className="my-3 w-full text-white">
                      <ActionShort {...action} url={action.url} />
                    </div>
                  )}
                  {showAction && !action && (
                    <div className="my-3 w-full">
                      <Share url={url} />
                    </div>
                  )}
                  {!showAction && action !== null && (
                    <Button to={action.url} className="my-3">
                      {action.cta || "Take Action"}
                    </Button>
                  )}
                  {secondaryLink &&
                    secondaryLink.link &&
                    secondaryLink.text && (
                      <div className="my-3">
                        <ButtonAlt to={secondaryLink.link}>
                          {secondaryLink.text}
                        </ButtonAlt>
                      </div>
                    )}
                </div>
              )}
            </div>
          </Container>
          {/* </AnimateIn> */}
        </div>
      </div>
    );
  }
);

Cta.defaultProps = {
  showAction: false,
  link: null,
  action: null,
};

export default Cta;
